.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1b1b1b;
}

.App h1{
  color: teal;
  font-family: 'Poppins', sans-serif;
  font-size: 50pt;
  letter-spacing: 1px;
  font-weight: 200;
  text-decoration: underline;
}